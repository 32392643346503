import React from 'react';
import classNames from 'classnames';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { productPageStyle } from './ProductPageStyle';
import { grayColor } from '../assets/jss/materialKitBase';

// https://github.com/xiaolin/react-image-gallery
// import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css';
// import { formatWeight } from '../helpers/formatterHelper';
import { AddToCartButton } from '../components/Button/AddToCartButton';
// import { CardHeader } from '../components/Card/CardHeader';
// import { CardBody } from '../components/Card/CardBody';
// import { Card } from '../components/Card/Card';
// import { CardFooter } from '../components/Card/CardFooter';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { CategoryNode, ProductNode } from '../interfaces/contentful';
import { Location } from 'history';
import { FormattedMessage, useIntl } from 'react-intl';
import { Price } from '../components/Price/Price';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { createCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg9.jpg';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(productPageStyle);

const ProductPage: React.FC<ProductPageProps> = ({ location, data, pageContext }) => {
  const { productPage, categories } = data;
  const { state } = location;
  const { infoMessages } = pageContext;

  const [cookies] = useCookies(['SeeInvisible']);
  if (typeof window !== `undefined` && !cookies['SeeInvisible'] && productPage.invisible === true) {
    navigate('/');
    return null;
  }

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classNames(classes.productPage)}>
      <Helmet>
        <title>{productPage.title}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />
      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            {state && state.categoryUrl && state.categoryTitle && (
              <Link component={GatsbyLink} to={state.categoryUrl} className={classes.link}>
                <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" /> {state.categoryTitle}
              </Link>
            )}
            <Typography color="inherit">{productPage.title}</Typography>
          </Breadcrumbs>
        </div>
      </Parallax>
      <section className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <GatsbyImage image={productPage.coverImage.gatsbyImageData} alt={productPage.coverImage.title} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h2 className={classes.title}>{productPage.title}</h2>
                <h3 className={classes.mainPrice}>
                  {productPage.unit}: <span style={{ color: grayColor[22] }}>{productPage.unitValue}</span>
                  <br />
                  <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                  <Link component={GatsbyLink} to={`/categories/${productPage.category.slug}`}>
                    {productPage.category.title}
                  </Link>
                  <br />
                  <FormattedMessage id="general.suppliedBy" defaultMessage="Supplied by" />:{' '}
                  <Link component={GatsbyLink} to={`/suppliers/${productPage.suppliedBy.slug}`}>
                    {productPage.suppliedBy.name}
                  </Link>
                  <br />
                  <FormattedMessage id="general.price" defaultMessage="Price" />: <Price product={productPage} />
                </h3>
                {productPage.description && productPage.description.childMarkdownRemark && (
                  <div
                    style={{ overflow: 'auto' }}
                    dangerouslySetInnerHTML={{ __html: productPage.description.childMarkdownRemark.html }}
                  />
                )}
                {/*InfoBox*/}
                <span dangerouslySetInnerHTML={{ __html: infoMessages.join('<br />') }} />
                <br />
                <br />
                <AddToCartButton product={productPage} quantity={1} />
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductPage;

interface ProductPageProps {
  location: Location<{ categoryUrl?: string; categoryTitle?: string }>;
  data: {
    productPage: ProductNode;
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
  pageContext: {
    infoMessages: string[];
  };
}

export const productQuery = graphql`
  query($slug: String!, $locale: String!) {
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
    productPage: contentfulRegionalProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contentful_id
      title
      slug
      price
      unit
      unitValue
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      category {
        title
        slug
      }
      suppliedBy {
        name
        slug
        location {
          lat
          lon
        }
      }
      coverImage {
        title
        gatsbyImageData(layout: CONSTRAINED)
      }
      notAvailableReason
      invisible
    }
  }
`;
