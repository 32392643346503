import { createStyles } from '@material-ui/core/styles';
import {
  section,
  container,
  title,
  grayColor,
  mainRaised,
  main,
  textCenter,
  gridItem,
  gridContainer,
  cardTitle,
} from '../assets/jss/materialKitBase';

export const productPageStyle = createStyles({
  parallax: {
    height: '70vh',
    overflow: 'hidden',
    // maxHeight: '600px',
    // height: 'auto',
    backgroundPosition: 'bottom center',
    // marginTop: '-70px',
  },
  textCenter,
  gridItem,
  gridContainer,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
  },
  cardDescription: {
    textAlign: 'center',
    color: grayColor[0],
  },
  justifyContentBetween: {
    justifyContent: 'space-between!important',
  },
  container: {
    ...container,
    zIndex: 2,
  },
  section: {
    ...section,
    padding: '70px 0px',
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  title: {
    ...title,
    marginBottom: 0,
  },
  sectionGray: {
    background: grayColor[14],
  },
  mainPrice: {
    margin: '10px 0px 25px',
  },
  mainRaised: {
    ...mainRaised,
  },
  main,
  productPage: {
    '& $mainRaised': {
      margin: '-40vh 0 0',
      padding: '40px',
      // margin: '2vh 0 0',
      // padding: '20px',
    },
    backgroundColor: grayColor[2],
    '& .image-gallery-slide img': {
      borderRadius: '3px',
      maxWidth: '300px',
      height: 'auto',
    },
    '& .image-gallery-swipe': {
      margin: '30px 0px',
      overflow: 'hidden',
      width: '100%',
      height: 'auto',
      textAlign: 'center',
    },
    '& .image-gallery-thumbnails > .image-gallery-thumbnails-container a': {
      '&.active > div': {
        opacity: '1',
        borderColor: grayColor[6],
      },
      '& > div': {
        width: '80%',
        maxWidth: '85px',
        margin: '0 auto',
        padding: '8px',
        display: 'block',
        border: '1px solid transparent',
        background: 'transparent',
        borderRadius: '3px',
        opacity: '.8',
      },
      '& > div img': {
        borderRadius: '3px',
        width: '100%',
        height: 'auto',
        textAlign: 'center',
      },
    },
  },
  cardRelatedProduct: {
    marginTop: '30px',
  },
});
